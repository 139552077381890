const defaultOptions = {
    immediate: true,
    interval: 60000,
    appendNew: false,
};

export default function usePolled(url, query = {}, options = {}) {
    const timer = ref(null);

    const polling = ref(false);
    const data = ref([]);

    const _options = {
        ...defaultOptions,
        ...options,
    };

    const { refresh } = useApiFetch(url, '', {
        query,
        onResponse: ({ response }) => {
            polling.value = false;

            if (!response.ok) {
                data.value = [];

                return;
            }

            const { _data } = response;

            if (!_data?.data?.length) {
                return;
            }

            if (_options.appendNew) {
                const { data: value } = _data ?? { data: [] };

                const ts = Date.now();
                value.forEach((item, index) => {
                    item.id = item.id ?? `${ts}-${index}`;

                    data.value.push(item);
                });

                return;
            }

            data.value = _data.data ?? [];
        },
        immediate: false,
        watch: false,
    });

    /**
     * Stop polling
     */
    const stop = () => {
        if (!timer.value) {
            return;
        }

        clearInterval(timer.value);
        polling.value = false;
        timer.value = null;
    };

    /**
     * Start polling
     */
    const start = () => {
        if (timer.value) {
            return;
        }

        timer.value = setInterval(() => {
            if (polling.value) {
                return;
            }

            polling.value = true;

            refresh();
        }, _options.interval ?? 3000);
    };

    onMounted(() => {
        if (!_options.immediate) {
            return;
        }

        start();
    });

    onBeforeUnmount(() => {
        stop();
    });

    return {
        data,
        polling,
        start,
        stop,
    };
}
