<script setup>
const { $role } = useNuxtApp();

const { checkProductManagementAccess } = useUserPermissions();

const props = defineProps({
    product: {
        type: Object,
        required: true,
    },
    active: {
        type: Boolean,
    },
    onClick: Function,
    onClickNoAccess: Function,
    onClickDisabled: Function,
});

const cantAccess = computed(
    () => !checkProductManagementAccess(props.product.uuid)
);

const clicked = (event) => {
    if (cantAccess.value && !$role('admin')) {
        event.preventDefault();

        props.onClickDisabled(event);
        return;
    }

    // If the user is an admin and the product is not accessible, we should
    // show the sales page.
    if (cantAccess.value) {
        event.preventDefault();

        props.onClickNoAccess(event);
        return;
    }

    props.onClick(event);
};
</script>

<template>
    <li
        :data-product-uuid="product.uuid"
        :data-product-active="active"
        class="px-3 md:px-5 py-1 w-full"
        :class="{ 'bg-slate-100 text-slate-500': cantAccess }"
    >
        <NuxtLink
            exact-active-class="active"
            class="flex gap-1 cursor-pointer"
            :class="{
                'font-bold': active,
                'hover:text-slate-400': !cantAccess,
            }"
            @click="clicked"
        >
            <IconLock v-if="cantAccess" class="w-4 h-4" />
            <span class="whitespace-nowrap" v-text="product.name" />
        </NuxtLink>
    </li>
</template>
