<script setup>
/**
 * Notification Types:
 * @type
 * case Info = 1;
 * case Warning = 2;
 * case Error = 3;
 * case Success = 4;
 */

/**
 * Notification Channels:
 * @channel
 * case Log = 1; // Nicht für das Nuxt.js Frontend relevant
 * case ControlPanel = 2; // Notification center (Panel opened via Bell)
 * case PushNotification = 3; // Push Notification shown in the frontend (Top right corner)
 * case Mail = 4;  // Nicht für das Nuxt.js Frontend relevant
 */

const props = defineProps({
    uuid: { type: String, required: true },
    headline: String,
    message: String,
    type: Number,
    channel: Number,
    hasRead: Boolean,
});

const closed = ref(false);

const currenTime = computed(() => {
    const today = new Date();

    return {
        date: {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate(),
        },
        time: {
            h: today.getHours(),
            m: today.getMinutes(),
            s: today.getSeconds(),
        },
    };
});

const { execute: markAsRead } = useApiFetch(
    `/api/v1/notifications/${props.uuid}`,
    `notifications-patch-${props.uuid}`,
    {
        body: {
            uuid: props.uuid,
            dateTime: currenTime.value,
        },
        method: 'PATCH',
        immediate: false,
        watch: false,
    }
);

const bgColor = computed(() => {
    if (props.type === 3 && !props.hasRead) return 'bg-theme-pink';
    if (props.type === 2 && !props.hasRead) return 'bg-brand';
    if (props.type === 1 && !props.hasRead) return 'bg-slate-400';

    return 'bg-theme-green';
});

const emit = defineEmits(['read']);

const clicked = () => {
    if (props.hasRead) {
        closed.value = true;

        return;
    }

    markAsRead()
        .then(() => {
            emit('read', props.uuid);
        })
        .catch((error) => {
            console.error(error);
        });
};

onMounted(() => {
    if (props.hasRead) {
        closed.value = true;
    }
});
</script>

<template>
    <button
        type="button"
        class="flex gap-3 text-left items-stretch w-full group"
        :class="{ 'bg-theme-green/10': hasRead }"
        @click="clicked"
    >
        <div class="w-25 pl-8 relative pt-5">
            <span class="absolute left-0 top-0 w-1 h-full" :class="bgColor" />
            <div v-if="hasRead" class="min-w-full flex justify-center">
                <div class="w-6">
                    <IconCheck class="w-5" />
                </div>
            </div>
            <div v-else class="min-w-full flex justify-center">
                <IconMail2 v-if="type === 1" class="group-hover:hidden w-6" />
                <IconMailer v-if="type === 2" class="group-hover:hidden w-6" />
                <IconBell
                    v-if="type === 3"
                    color="#DD508C"
                    class="group-hover:hidden w-6"
                />
                <IconEye class="hidden group-hover:block w-6" />
            </div>
        </div>
        <div class="pl-4 py-4 w-full pr-8">
            <div v-if="headline" class="text-sm leading-tight relative">
                <strong>{{ headline }}</strong>
            </div>
            <div>
                <p
                    v-if="message"
                    class="text-xs text-slate-400"
                    v-html="message"
                />
            </div>
        </div>
    </button>
</template>
