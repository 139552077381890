<script setup>
import { useTeamsStore } from '~/stores/teams.js';

const props = defineProps({
    currentTeam: Object,
    teams: { type: Array, default: () => [] },
});

const storeTeams = useTeamsStore();
const router = useRouter();
const { urlParams, navigateToTeam } = useAdminUrl();

const teamSearch = ref('');
const searchedTeams = computed(() => {
    if (!teamSearch.value) {
        return props.teams;
    }

    return filterObjectArray(props.teams, teamSearch.value);
});

const addModal = ref();
const editModal = ref();

const show = ref(false);

const open = () => {
    show.value = true;
};

const close = () => {
    teamSearch.value = '';
    show.value = false;
};

defineExpose({
    open,
    close,
});

function filterObjectArray(data, query) {
    const lowerCaseQuery = query.toLowerCase();
    return data.filter(({ name, city, contact_person }) => {
        return (
            name?.toLowerCase().includes(lowerCaseQuery) ||
            city?.toLowerCase().includes(lowerCaseQuery) ||
            contact_person?.name?.toLowerCase().includes(lowerCaseQuery)
        );
    });
}

function selectTeam(uuid) {
    storeTeams.setActiveTeam(uuid, props.teams);

    navigateToTeam(uuid);

    router.push({
        name: 'admin-team-product-dashboard',
        params: {
            team: uuid,
            product: urlParams.value.product,
        },
    });
    close();
}

function addTeam() {
    close();
    addModal.value.open();
}

function editTeam(item) {
    close();
    editModal.value.open(item);
}

function cancelAddTeam() {
    addModal.value.close();
    open();
}

function cancelEditTeam() {
    editModal.value.close();
    open();
}
</script>

<template>
    <CommonModal
        id="manage-locations-modal"
        name="manage-locations"
        :show="show"
        @close="close"
    >
        <template #header>
            <h2 class="h2">
                {{ $t('admin.location.administration') }}
            </h2>
        </template>
        <MessagesInfo class="mb-6">
            <span class="font-bold">
                {{ $t('admin.manage_locations_notice_header') }}
            </span>
            <br />
            <i18n-t tag="span" keypath="admin.manage_locations_notice_text">
                <template #icon>
                    <IconHome class="inline-block !w-5 !h-5" />
                </template>
            </i18n-t>
        </MessagesInfo>
        <div class="mb-6">
            <InputSearch2
                id="search"
                v-model="teamSearch"
                :label="$t('global.search')"
                name="search"
                type="search"
            />
        </div>
        <Table>
            <template #head>
                <TableHeader class="md:w-1/3" sortable>
                    <span class="hidden md:inline">
                        {{ $t('admin.account.company_location') }}
                    </span>
                    <span class="md:hidden">{{ $t('global.company') }}</span>
                </TableHeader>
                <TableHeader sortable hide-mobile>
                    {{ $t('global.city') }}
                </TableHeader>
                <TableHeader sortable hide-mobile>
                    {{ $t('admin.account.contact_person') }}
                </TableHeader>
                <TableHeader>
                    <span class="sr-only">
                        {{ $t('admin.account.edit_company') }}
                    </span>
                </TableHeader>
            </template>
            <template #body>
                <tr
                    v-for="team in searchedTeams"
                    :key="team.uuid"
                    class="hover:bg-brand/40 cursor-pointer"
                    :class="{ 'bg-brand': team.isActive }"
                >
                    <td
                        class="group md:w-1/3"
                        @click.prevent="selectTeam(team.uuid)"
                    >
                        <div class="flex items-center">
                            <IconTeamArrow />
                            <div class="flex flex-col">
                                <span
                                    class="font-bold leading-tight flex gap-4 items-center"
                                >
                                    {{ team.display_name }}
                                    <IconHome v-if="team.is_main" />
                                </span>
                                <span
                                    class="md:hidden text-xs text-slate-400 leading-tight"
                                >
                                    {{ team.city }}
                                </span>
                                <span
                                    class="md:hidden text-xs text-slate-400 leading-tight"
                                >
                                    {{ team.contact }}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td
                        class="hidden md:table-cell font-bold"
                        @click.prevent="selectTeam(team.uuid)"
                    >
                        {{ team.city }}
                    </td>
                    <td
                        class="hidden md:table-cell"
                        @click.prevent="selectTeam(team.uuid)"
                    >
                        {{ team.contact_person?.name }}
                    </td>
                    <td class="w-12 p-0">
                        <button
                            class="flex justify-center w-full h-full"
                            @click.prevent="editTeam(team)"
                        >
                            <IconEdit class="w-4" />
                        </button>
                    </td>
                </tr>
            </template>
        </Table>
        <MessagesError v-if="teams.length === 0" class="mt-6">
            <strong class="text-lg">
                {{ $t('global.no_locations_found') }}
            </strong>
        </MessagesError>

        <template #footer>
            <div
                class="w-full flex flex-col-reverse sm:flex-row gap-3 justify-center items-center sm:justify-between"
            >
                <CommonButton outline icon arrow @click.prevent="close">
                    {{ $t('global.cancel') }}
                </CommonButton>
                <CommonButton primary icon @click.prevent="addTeam">
                    <template #custom-icon>
                        <IconPlus />
                    </template>
                    {{ $t('admin.account.add_company_or_location') }}
                </CommonButton>
            </div>
        </template>
    </CommonModal>
    <ModalManageLocationsAdd ref="addModal" @cancel:add-team="cancelAddTeam" />
    <ModalManageLocationsEdit
        ref="editModal"
        :current-team-uuid="currentTeam.uuid"
        @cancel:edit-team="cancelEditTeam"
    />
</template>
