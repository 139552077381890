<script setup>
const props = defineProps({
    locked: Boolean,
    isOpen: Boolean,
    icon: String,
    label: String,
    url: String,
    to: String,
    show: {
        type: [Function, Boolean],
        default: true,
    },
    onClick: Function,
});

const { urlPath } = useAdminUrl();

const show = computed(() => {
    if (typeof props.show === 'function') {
        return props.show();
    }

    return props.show;
});

const clicked = (event) => {
    if (props.locked) {
        event.preventDefault();
        return;
    }

    props.onClick(event);
};
</script>

<template>
    <li v-if="show" :class="{ 'pointer-events-none': locked }">
        <NuxtLink
            exact-active-class="active [&>svg]:text-slate-800"
            :to="to ?? `${urlPath}/${url}`"
            @click="clicked"
        >
            <component
                :is="locked ? 'IconLock' : icon"
                class="w-5 h-auto max-h-5 text-slate-400 stroke-current"
            />
            <span v-if="isOpen" :class="{ 'opacity-30': locked }">
                {{ label }}
            </span>
        </NuxtLink>
    </li>
</template>
