<script setup>
/**
 * Notification Types:
 * @type
 * case Info = 1;
 * case Warning = 2;
 * case Error = 3;
 * case Success = 4;
 */
const show = ref(false);

const props = defineProps({
    message: {
        type: Object,
    },
});
const slots = useSlots();
const emit = defineEmits(['close']);

const content = ref(props.message);

const type = computed(() => {
    const { type: _type } = props.message;

    if (_type === 'warning' || _type == 2) {
        return 'warning';
    }

    if (_type === 'error' || _type == 3) {
        return 'error';
    }

    if (_type === 'success' || _type == 4) {
        return 'success';
    }

    return 'info';
});

const hasContent = computed(() => {
    return !!content.value.headline || !!content.value.message;
});

const hasHeadline = computed(() => {
    return !!content.value.headline && !slots.headline;
});

const timer = ref();

const clearTimer = () => {
    clearTimeout(timer.value);
    timer.value = null;
};

const close = () => {
    clearTimer();
    show.value = false;

    emit('close', content.value);
};

onMounted(() => {
    if (!!timer.value || !hasContent.value) {
        return;
    }

    show.value = true;

    timer.value = setTimeout(() => {
        close();
    }, props.message.duration ?? 4000);
});

onBeforeUnmount(() => {
    clearTimer();
});
</script>

<template>
    <div
        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
        <div class="p-4">
            <div class="flex items-center">
                <div class="flex-shrink-0">
                    <IconCheckCircle
                        v-if="type === 'success'"
                        class="h-6 w-6 text-theme-success"
                    />
                    <IconError
                        v-if="type === 'error'"
                        class="h-6 w-6 text-theme-danger"
                    />
                    <IconInfo
                        v-if="type === 'info'"
                        class="h-6 w-6 text-theme-blue"
                    />
                    <IconExclamation
                        v-if="type === 'warning'"
                        class="h-6 w-6 text-theme-warning"
                    />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p
                        v-if="hasHeadline"
                        class="mb-1 text-sm font-medium text-gray-900"
                    >
                        <slot name="headline">
                            {{ content.headline }}
                        </slot>
                    </p>
                    <p class="text-sm text-gray-500">
                        <slot name="message">
                            {{ content.message }}
                        </slot>
                    </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                    <button
                        type="button"
                        class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        @click.stop.prevent="close"
                    >
                        <span class="sr-only">Close</span>
                        <svg
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
