<script setup>
const props = defineProps({
    autoInit: {
        type: Boolean,
        default: true,
    },
});

const { public: config } = useRuntimeConfig();

const { clientId, token, widgetId } = config.helpspace;
const canInit = computed(() => {
    if (!import.meta.client) {
        return false;
    }

    return clientId && token && widgetId;
});

if (canInit.value) {
    useHead({
        script: [
            {
                id: 'helpspace-widget-script',
                'data-token': token,
                'data-client-id': clientId,
                'data-widget-id': widgetId,
                'data-auto-init': props.autoInit,
                src: 'https://cdn.helpspace.com/widget/widget-v1.js',
                defer: true,
                tagPosition: 'bodyClose',
            },
        ],
    });
}

onMounted(() => {
    if (!import.meta.client || !props.autoInit) {
        return;
    }

    // check if the HelpWidget is available
    if (!canInit.value || !window.HelpWidget) {
        return;
    }

    window.HelpWidget('init');
});

onBeforeUnmount(() => {
    if (import.meta.client && window.HelpWidget) {
        window.HelpWidget('destroy');
    }
});
</script>

<template>
    <span class="absolute w-0 h-0 hidden" />
</template>
