<script setup>
import Message from './Message.vue';

const { data: profile } = useAuth();

const { $echo } = useNuxtApp();

if (!$echo.inactive) {
    $echo.channel('customer-notifications.push').listen('.new', (event) => {
        add(event);
    });

    $echo.channel('notifications').listen('.new-message', (event) => {
        add(event);
    });

    $echo
        .private(`customer-notifications.push.${profile.value.uuid}`)
        .listen('.new', (event) => {
            add(event);
        });
}

const messages = ref([]);

const remove = (notificationId) => {
    // remove notification by passed id
    messages.value = messages.value.filter((n) => n.id !== notificationId);
};

const add = (message, timeStamp = null) => {
    messages.value.push({
        id: timeStamp ?? Date.now(),
        type: message.type,
        message: message.message,
        headline: message.headline,
    });
};

useWindowEvent('new-notification', (event) => {
    add(event.detail, event.timeStamp);
});
</script>

<template>
    <div
        id="notification-overlay-target"
        aria-live="polite"
        role="status"
        class="fixed z-[100000] inset-0 flex items-end pointer-events-none px-4 py-24 sm:!items-start"
    >
        <div class="w-full flex flex-col items-center space-y-4 sm:!items-end">
            <!-- Notifications will be appended here -->
            <template v-for="message in messages" :key="message.id">
                <Message :message="message" @close="remove(message.id)" />
            </template>
        </div>
    </div>
</template>
